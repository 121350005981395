<template>
	<div>
		<!-- タイトル -->
		<PageTitle title-name="講師一覧" />
		<VmBox>
			<template #header>
				<v-col class="csv-upload-area">
					<CsvUploadArea type="TEACHER" @reload="reloadTable" />
				</v-col>
			</template>
			<template #content>
				<v-data-table
					v-model="selected"
					:headers="headers"
					:items="teacherArray"
					:search="search"
					multi-sort
					class="elevation-1 overflow-y-auto"
					disable-pagination
					no-data-text="講師がいません"
					hide-default-footer
					:loading="isLoading"
					fixed-header
					:height="teacherArray.length > 15 ? 48 * 15 - 1 : undefined"
				>
					<template v-slot:top>
						<v-dialog v-model="showAddDialog" max-width="500px">
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openAddDialog">
									新規講師
								</v-btn>
							</template>
							<AdminAddFormCard2
								@closeAction="closeAdd"
								@addAction="add"
								:editedItem="editedItem"
								formTitle="講師追加"
								@changeFile="changeFile($event)"
								:loading="isLoading"
							/>
						</v-dialog>
						<v-dialog v-model="dialog" max-width="500px">
							<AdminAddFormCard2
								@closeAction="close"
								@addAction="save"
								:editedItem="filteredEditItem"
								formTitle="講師情報編集"
								buttonTitle="edit"
								@changeFile="changeFile($event)"
								:loading="isLoading"
							/>
						</v-dialog>
						<v-dialog v-model="dialogDelete" max-width="500px">
							<AdminDeleteFormCard
								@closeDeleteAction="closeDelete"
								@deleteItemConfirmAction="deleteItemConfirm"
								formTitle="この講師情報を削除しますか？"
							/>
						</v-dialog>
						<v-text-field v-model="search" label="Search" class="filter-item"></v-text-field>
					</template>
					<!-- 文字数制限をかけるため以下のような実装をする -->
					<template v-for="(headerItem, key, index) in headers" v-slot:[`item.${headerItem.value}`]="{ item }">
						<p :style="{ margin: '0' }" :key="index">
							{{ $limitedText30(item[headerItem.value]) }}
						</p>
					</template>
					<template v-slot:[`item.edit`]="{ item }">
						<v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
					</template>
					<template v-slot:[`item.delete`]="{ item }">
						<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
					</template>
					<template v-slot:[`item.image`]="{ item }">
						<img :src="item.image" class="table-img" />
					</template>
				</v-data-table>
			</template>
		</VmBox>
	</div>
</template>

<script>
import AdminDeleteFormCard from '../Organisms/AdminDeleteFormCard'
import CsvUploadArea from '../Molecules/CsvUploadArea.vue'
import PageTitle from '../Atoms/PageTitle'
import VmBox from '../Atoms/VmBox'
import AdminAddFormCard2 from '../Organisms/AdminAddFormCard2'

export default {
	name: 'AdminTeacherList',
	components: {
		AdminDeleteFormCard,
		CsvUploadArea,
		PageTitle,
		VmBox,
		AdminAddFormCard2
	},
	data: () => ({
		teachers: null,
		selected: [],
		teacherArray: [],
		isShowOnlyDone: false,
		isShowFromToday: false,
		search: '',
		dialog: false,
		showAddDialog: false,
		dialogDelete: false,
		selectedTeacherId: 0,
		editedItem: {
			teacherId: {
				value: '',
				type: 'display',
				required: false,
				label: 'id'
			},
			name: {
				value: '',
				type: 'textField',
				required: true,
				label: '名前',
				default: ''
			},
			teacherName: {
				value: '',
				type: 'textField',
				required: true,
				label: '講師名',
				default: ''
			},
			email: {
				value: '',
				type: 'textField',
				required: true,
				label: 'email',
				default: ''
			},
			password: {
				value: '',
				type: 'textField',
				required: false,
				label: 'password',
				default: ''
			},
			lessonCourseIds: {
				value: '',
				type: 'textField',
				required: true,
				label: '担当コース',
				default: ''
			},
			birthDay: {
				value: null,
				type: 'date',
				required: true,
				label: '生年月日'
			},
			sex: {
				value: 0,
				type: 'number',
				required: false,
				label: '性別'
			},
			country: {
				value: '',
				type: 'textField',
				required: false,
				label: '国',
				default: ''
			},
			skypeId: {
				value: '',
				type: 'textField',
				required: false,
				label: 'スカイプID',
				default: ''
			},
			selfIntroduction: {
				value: '',
				type: 'textarea',
				required: false,
				label: '自己紹介',
				default: ''
			},
			specialities: {
				value: '',
				type: 'textField',
				required: false,
				label: '得意分野',
				default: ''
			},
			hobbies: {
				value: '',
				type: 'textField',
				required: false,
				label: '趣味・特技',
				default: ''
			},
			catchPhrase: {
				value: '',
				type: 'textField',
				required: false,
				label: 'キャッチフレーズ',
				default: ''
			},
			background: {
				value: '',
				type: 'textarea',
				required: false,
				label: '経歴',
				default: ''
			},
			category: {
				value: '',
				type: 'select',
				required: false,
				label: 'カテゴリー',
				options: ['JAPANESE', 'NATIVE']
			},
			privLevel: {
				value: '',
				type: 'select',
				required: false,
				label: 'プライベートレベル',
				options: ['BEGINNER', 'EXPERT']
			},
			convLevel: {
				value: '',
				type: 'select',
				required: false,
				label: '会話レベル',
				options: ['BEGINNER', 'EXPERT']
			},
			isMainBusiness: {
				value: false,
				type: 'boolean',
				required: true,
				label: '本業',
				default: false
			},
			compatibleLevel: {
				value: '',
				type: 'textField',
				required: false,
				label: '対応可能レベル',
				default: ''
			},
			feeForFirstLesson: {
				value: 0,
				type: 'number',
				required: true,
				label: '初回レッスン料金',
				default: 0
			},
			feeForStudent: {
				value: 0,
				type: 'number',
				required: true,
				label: '学生レッスン料金',
				default: 0
			},
			feeForRegular: {
				value: 0,
				type: 'number',
				required: true,
				label: '通常レッスン料金',
				default: 0
			},
			isVisibleForPriv: {
				value: 0,
				type: 'boolean',
				required: true,
				label: 'プライベートリスト表示',
				default: false
			},
			isVisibleForConv: {
				value: 0,
				type: 'boolean',
				required: true,
				label: '会話リスト表示',
				default: false
			},
			isVisibleForText: {
				value: 0,
				type: 'boolean',
				required: true,
				label: 'テキストリスト表示',
				default: false
			},
			feeMessage: {
				value: '',
				type: 'textField',
				required: false,
				label: '料金メッセージ',
				default: ''
			},
			classInterval: {
				value: 0,
				type: 'number',
				required: false,
				label: '授業間の休み'
			},
			minAvailableStudentAge: {
				value: 0,
				type: 'number',
				required: false,
				label: 'レッスン可能年齢の下限'
			},
			isRemindedConv2Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド2時間前',
				default: false
			},
			isRemindedConv24Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド24時間前',
				default: false
			},
			isRemindedConv72Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: '会話リマインド72時間前',
				default: false
			},
			isRemindedPriv2Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド2時間前',
				default: false
			},
			isRemindedPriv24Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド24時間前',
				default: false
			},
			isRemindedPriv72Hours: {
				value: false,
				type: 'boolean',
				required: false,
				label: 'プライベートリマインド72時間前',
				default: false
			},
			isAdmin: {
				value: 0,
				type: 'boolean',
				required: true,
				label: '管理者',
				default: false
			},
			isOperator: {
				value: 0,
				type: 'boolean',
				required: true,
				label: '運用者',
				default: false
			},
			adminCapabilities: {
				value: '',
				type: 'textField',
				required: false,
				label: 'アクセスできる管理画面',
				default: ''
			},
			canToggleNewLesson: {
				value: 0,
				type: 'boolean',
				required: true,
				label: '受付可能状況の操作',
				options: false
			},
			blogUrl: {
				value: '',
				type: 'textField',
				required: false,
				label: 'ブログURL',
				default: ''
			},
			podcastEmbedLink: {
				value: '',
				type: 'textarea',
				required: false,
				label: 'ポッドキャスト埋め込みリンク',
				default: ''
			}
		},
		isLoading: false
	}),
	computed: {
		headers() {
			return [
				{ text: 'Actions', value: 'edit', align: 'start', sortable: false },
				{
					text: 'teacherId',
					value: 'teacherId'
				},
				{ text: '名前', value: 'name' },
				{ text: '講師名', value: 'teacherName' },
				{ text: 'email', value: 'email' },
				{ text: '担当コース', value: 'lessonCourseIds' },
				{ text: '生年月日', value: 'birthDay' },
				{ text: '性別', value: 'sex' },
				{ text: '国', value: 'country' },
				{ text: 'スカイプID', value: 'skypeId' },
				{ text: '自己紹介', value: 'selfIntroduction' },
				{ text: '得意分野', value: 'specialities' },
				{ text: '趣味・特技', value: 'hobbies' },
				{ text: 'キャッチフレーズ', value: 'catchPhrase' },
				{ text: '経歴', value: 'background' },
				{ text: 'カテゴリー', value: 'category' },
				{ text: 'プライベートレベル', value: 'privLevel' },
				{ text: '会話レベル', value: 'convLevel' },
				{ text: '本業', value: 'isMainBusiness' },
				{ text: '対応可能レベル', value: 'compatibleLevel' },
				{ text: '初回レッスン料金', value: 'feeForFirstLesson' },
				{ text: '学生レッスン料金', value: 'feeForStudent' },
				{ text: '通常レッスン料金', value: 'feeForRegular' },
				{ text: 'プライベートリスト表示', value: 'isVisibleForPriv' },
				{ text: '会話リスト表示', value: 'isVisibleForConv' },
				{ text: 'テキストリスト表示', value: 'isVisibleForText' },
				{ text: '料金メッセージ', value: 'feeMessage' },
				{ text: '授業間の休み', value: 'classInterval' },
				{ text: 'レッスン可能年齢の下限', value: 'minAvailableStudentAge' },
				{ text: '会話リマインド2時間前', value: 'isRemindedConv2Hours' },
				{ text: '会話リマインド24時間前', value: 'isRemindedConv24Hours' },
				{ text: '会話リマインド72時間前', value: 'isRemindedConv72Hours' },
				{ text: 'プライベートリマインド2時間前', value: 'isRemindedPriv2Hours' },
				{ text: 'プライベートリマインド24時間前', value: 'isRemindedPriv24Hours' },
				{ text: 'プライベートリマインド72時間前', value: 'isRemindedPriv72Hours' },
				{ text: '管理者', value: 'isAdmin' },
				{ text: '運用者', value: 'isOperator' },
				{ text: 'アクセスできる管理画面', value: 'adminCapabilities' },
				{ text: '受付可能状況の操作', value: 'canToggleNewLesson' },
				{ text: 'テストアカウント', value: 'isTestAccount' },
				{ text: 'ブログURL', value: 'blogUrl' },
				{ text: 'ポッドキャスト埋め込みリンク', value: 'podcastEmbedLink' },
				{ text: '画像', value: 'image' },
				{ text: 'Actions', value: 'delete', sortable: false }
			]
		},
		filteredEditItem() {
			// this.editedItemからpasswordを除外した新しいオブジェクトを返す
			const { password, ...filteredItem } = this.editedItem
			return filteredItem
		}
	},
	props: [],
	created() {
		this.fetchInitialData()
		this.reloadTable()
	},
	mounted() {},
	methods: {
		reloadTable() {
			this.fetchTeachers()
				.then(() => {
					this.makeTeacherArray()
				})
				.catch((error) => {
					console.log(error)
				})
		},
		// API通信を行うのに必要な情報を取得する
		fetchInitialData() {
			const user_info = this.$store.getters['user/getUserInfo']
			this.accessToken = user_info.accessToken
		},
		fetchTeachers() {
			this.isLoading = true
			return new Promise((resolve, reject) => {
				const header = {
					headers: {
						Authorization: `Bearer ${this.accessToken}`
					}
				}
				this.axios
					.get(`/api/teachers`, header)
					.then((response) => {
						this.teachers = response.data.teachers
						this.isLoading = false
						resolve()
					})
					.catch((error) => {
						console.log(error)
						reject()
					})
			})
		},
		makeTeacherArray() {
			this.teacherArray = []
			this.teachers.forEach((teacher, index) => {
				// Teacher - TeacherLessonCoursesで結合しているので、複数コースを担当している人がいたら
				// 複数データが連続で入ることになる（2コース担当していたら2連続）
				// 連続で同じ人のデータが入っていたら"lessonCourseIds"の値を"1,2"みたいな感じでコンマ区切りで表示できるようにする
				if (index != 0 && teacher.id === this.teachers[index - 1].id) {
					const length = this.teacherArray.length
					this.teacherArray[length - 1].lessonCourseIds += ',' + teacher['TeacherLessonCourses.lessonCourseId']
				} else {
					const teacherData = {
						teacherId: teacher.id,
						name: teacher.name,
						teacherName: teacher.teacherName,
						email: teacher.email,
						lessonCourseIds: teacher['TeacherLessonCourses.lessonCourseId'],
						birthDay: teacher.birthDay ? this.$moment(teacher.birthDay).format('YYYY-MM-DD') : '',
						sex: teacher.sex,
						country: teacher.country,
						skypeId: teacher.skypeId,
						selfIntroduction: teacher.selfIntroduction,
						specialities: teacher.specialities,
						hobbies: teacher.hobbies,
						catchPhrase: teacher.catchPhrase,
						background: teacher.background,
						category: teacher.category,
						privLevel: teacher.privLevel,
						convLevel: teacher.convLevel,
						isMainBusiness: teacher.isMainBusiness,
						compatibleLevel: teacher.compatibleLevel,
						feeForFirstLesson: teacher.feeForFirstLesson,
						feeForStudent: teacher.feeForStudent,
						feeForRegular: teacher.feeForRegular,
						isVisibleForPriv: teacher.isVisibleForPriv,
						isVisibleForConv: teacher.isVisibleForConv,
						isVisibleForText: teacher.isVisibleForText,
						feeMessage: teacher.feeMessage,
						minAvailableStudentAge: teacher.minAvailableStudentAge,
						classInterval: teacher.classInterval,
						isRemindedConv2Hours: Boolean(teacher.isRemindedConv2Hours),
						isRemindedConv24Hours: Boolean(teacher.isRemindedConv24Hours),
						isRemindedConv72Hours: Boolean(teacher.isRemindedConv72Hours),
						isRemindedPriv2Hours: Boolean(teacher.isRemindedPriv2Hours),
						isRemindedPriv24Hours: Boolean(teacher.isRemindedPriv24Hours),
						isRemindedPriv72Hours: Boolean(teacher.isRemindedPriv72Hours),
						isAdmin: Boolean(teacher.isAdmin),
						isOperator: Boolean(teacher.isOperator),
						adminCapabilities: teacher.adminCapabilities,
						canToggleNewLesson: Boolean(teacher.canToggleNewLesson),
						isTestAccount: Boolean(teacher.isTestAccount),
						blogUrl: teacher.blogUrl,
						podcastEmbedLink: teacher.podcastEmbedLink,
						image: teacher.imageUrl ? teacher.imageUrl : require('../../assets/no_teacher.png')
					}
					this.teacherArray.push(teacherData)
				}
			})
		},
		editItem(item) {
			this.selectedTeacherId = item.teacherId
			this.mapItemToEditedItem(item)
			this.dialog = true
		},
		resetEditedForm() {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					this.editedItem[key].value = this.editedItem[key].type === 'number' ? 0 : ''
				}
			}
		},
		mapItemToEditedItem(item) {
			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key) && item.hasOwnProperty(key)) {
					this.editedItem[key].value = item[key]
				}
			}
		},
		deleteItem(item) {
			this.selectedTeacherId = item.teacherId
			this.mapItemToEditedItem(item)
			this.dialogDelete = true
		},
		deleteItemConfirm() {
			const header = {
				headers: {
					Authorization: `Bearer ${this.accessToken}`
				}
			}

			this.axios
				.delete(`/api/teachers/${this.selectedTeacherId}`, header)
				.then((response) => {
					console.log('response.data', response.data)
					this.closeDelete()
					this.reloadTable()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		close() {
			this.dialog = false
			this.$nextTick(() => {
				this.resetEditedForm()
			})
		},
		closeAdd() {
			this.showAddDialog = false
			this.$nextTick(() => {
				this.resetEditedForm()
			})
		},
		closeDelete() {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.resetEditedForm()
			})
		},
		save() {
			let formData = new FormData()

			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					formData.append(key, this.editedItem[key].value)
				}
			}
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.put(`/api/teachers/${this.selectedTeacherId}`, formData, header)
				.then((response) => {
					console.log('response.data', response.data)
					this.close()
					this.reloadTable()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		add() {
			let formData = new FormData()

			for (const key in this.editedItem) {
				if (this.editedItem.hasOwnProperty(key)) {
					formData.append(key, this.editedItem[key].value)
				}
			}
			formData.append('image', this.uploadedImage)

			const header = {
				headers: {
					Authorization: `Bearer ${this.accessToken}`,
					'Content-Type': 'multipart/form-data'
				}
			}

			this.axios
				.post(`/api/teachers`, formData, header)
				.then((response) => {
					console.log('response.data', response.data)
					this.closeAdd()
					this.reloadTable()
				})
				.catch((error) => {
					alert(error.response.data.error.message)
				})
		},
		changeFile(elem) {
			this.uploadedImage = ''
			console.log('elem', elem)
			const files = elem.target.files || elem.dataTransfer.files
			this.uploadedImage = files[0]
		},
		openAddDialog() {
			this.resetEditedForm()
			this.showAddDialog = true
		}
	}
}
</script>

<style lang="scss" scoped>
v-data-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	background-color: #dcc268;
}

thead.v-data-table-header {
	background-color: #dcc268;
}

.filter-area {
	padding: 0 20px;
}
.filter-item {
	padding: 10px 5px;
}
.table-img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}
.csv-upload-area {
	margin-bottom: 10px;
}
.v-data-table {
	white-space: nowrap;
}
</style>
