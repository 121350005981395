<template>
	<VmDialogCard
		:title="formTitle"
		:description="explanation"
		:buttonTitle="buttonTitle"
		:cancelButtonTitle="cancelButtonText"
		@click="add"
		@close="close"
		:loading="loading"
	>
		<template #cardContent>
			<div v-for="(item, key) in editedItem" :key="key">
				<div v-if="item.type === 'checkbox-group'">
					<div class="checkbox-group-label">
						<div v-if="item.required" class="requiredText">必須</div>
						<div v-else class="notRequired">任意</div>
						{{ item.label || key }}
					</div>
					<div class="field checkbox-group">
						<div class="flex-grow-1">
							<div v-for="(option, index) in item.options" :key="index">
								<v-checkbox v-model="item.value" :label="option.label" :value="option.value" hide-details></v-checkbox>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="item.type" class="field">
					<div v-if="item.required" class="requiredText">必須</div>
					<div v-else class="notRequired">任意</div>
					<div class="flex-grow-1">
						<v-text-field
							v-if="item.type === 'number'"
							v-model="item.value"
							:label="item.label || key"
							outlined
							type="number"
							background-color="white"
							hide-details
						></v-text-field>
						<v-text-field
							v-if="item.type === 'date'"
							v-model="item.value"
							:label="item.label || key"
							outlined
							type="date"
							background-color="white"
							hide-details
						></v-text-field>
						<div v-if="item.type === 'date'">
							{{ item.value }}
						</div>
						<v-text-field
							v-if="item.type === 'textField'"
							v-model="item.value"
							:label="item.label || key"
							outlined
							background-color="white"
							hide-details
						></v-text-field>
						<v-textarea
							v-else-if="item.type === 'textarea'"
							v-model="item.value"
							:label="item.label || key"
							outlined
							background-color="white"
							hide-details
						></v-textarea>
						<v-select
							v-else-if="item.type === 'select'"
							v-model="item.value"
							:items="item.options"
							:label="item.label || key"
							outlined
							background-color="white"
							hide-details
						></v-select>
						<v-select
							v-else-if="item.type === 'boolean'"
							v-model="item.value"
							:items="[true, false]"
							:label="item.label || key"
							outlined
							background-color="white"
							hide-details
						></v-select>
						<v-text-field
							v-if="item.type === 'dateTime'"
							v-model="formattedDateTime[key]"
							:label="item.label || key"
							outlined
							type="datetime-local"
							background-color="white"
							hide-details
							@change="updateDateTime($event, key)"
						></v-text-field>
						<div v-else-if="item.type === 'display'">
							<p>{{ item.label || key }}: {{ item.value }}</p>
						</div>
					</div>
				</div>
			</div>
			<form v-if="!noImage" class="imageArea">
				<p>画像</p>
				<input @change="changeFile" type="file" name="upfile[]" multiple accept="image/*" />
			</form>
		</template>
	</VmDialogCard>
</template>

<script>
import VmDialogCard from '../../components/Molecules/VmDialogCard'

export default {
	name: 'AdminAddFormCard2',
	components: {
		VmDialogCard
	},
	props: {
		editedItem: {
			type: Object,
			default: () => {}
		},
		formTitle: {
			type: String,
			default: ''
		},
		explanation: {
			type: String,
			default: ''
		},
		noImage: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		buttonTitle: {
			type: String,
			default: 'Add'
		},
		cancelButtonText: {
			type: String,
			default: 'Cancel'
		}
	},
	created() {
		this.setDefaultValues()
	},
	computed: {
		formattedDateTime: {
			get() {
				const formatted = {}
				for (const key in this.editedItem) {
					if (this.editedItem[key].type === 'dateTime') {
						formatted[key] = this.formatDateTimeLocal(this.editedItem[key].value)
					}
				}
				return formatted
			}
		}
	},
	methods: {
		close() {
			this.$emit('closeAction')
		},
		add() {
			this.$emit('addAction')
		},
		changeFile(elem) {
			this.$emit('changeFile', elem)
		},
		setDefaultValues() {
			for (const key in this.editedItem) {
				if (!this.editedItem[key].value && this.editedItem[key].default !== undefined) {
					this.editedItem[key].value = this.editedItem[key].default
				}
			}
		},
		updateDateTime(event, key) {
			const dateTimeValue = event
			const originalValue = this.editedItem[key].value
			const timeZone = this.$moment(originalValue).format('Z') // タイムゾーン部分を抽出
			const updatedValue = this.$moment(dateTimeValue).format('YYYY-MM-DDTHH:mm') + timeZone
			this.editedItem[key].value = updatedValue
		},
		formatDateTimeLocal(value) {
			return this.$moment(value).format('YYYY-MM-DDTHH:mm')
		}
	}
}
</script>
<style lang="scss" scoped>
.field {
	display: flex;
	margin-bottom: 16px;
}
p {
	white-space: pre-line;
}
.imageArea {
	margin-left: 16px;
}
.requiredText {
	margin-right: 16px;
	color: white;
	background-color: red;
	padding: 0 4px;
	border-radius: 4px;
	font-weight: bold;
	height: 24px;
}
.notRequired {
	margin-right: 16px;
	color: white;
	padding: 0 4px;
	border-radius: 4px;
	font-weight: bold;
	height: 24px;
}
.checkbox-group-label {
	margin-top: 20px;
	display: flex;
}

.checkbox-group {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 32px;
	margin-left: 50px;
}
</style>
