<template>
	<div>
		<VmDialogCard
			title="レッスン予約"
			buttonTitle="予約完了する"
			cancelButtonTitle="閉じる"
			@click="reserve"
			@close="cancel"
			:disabled="!buttonValidation || loading"
			:height="computedCardHeight"
			v-if="hasTickets"
		>
			<template #cardContent>
				<div>
					<div>
						<p class="reservation-subtitle">レッスン情報</p>
					</div>
					<div class="lesson-info-area">
						<div>
							<p>
								◎担当講師:
								<span>{{ teacherName }} 先生</span>
							</p>
						</div>
						<div>
							<p>
								◎受講コース:
								<span>{{ courseName }}</span>
							</p>
						</div>
						<div>
							<p>
								◎所要時間:
								<span>20分</span>
							</p>
						</div>
						<div>
							<SharedStudentInfo />
						</div>
					</div>
				</div>
				<div>
					<div class="display-flex">
						<p class="reservation-subtitle">レッスン時間</p>
						<TimeZoneIcon />
					</div>
					<!-- 開始日時のフォーム -->
					<DateAndTimeForm
						:formLabelText="$t('teacherSchedule.startTime')"
						:date.sync="lessonStartDate"
						:showedDate="lessonStartDate"
						:minDate="selectableMinDate"
						:maxDate="selectableMaxDate"
						:time.sync="lessonStartTime"
						:minuteInterval="5"
						:minTime="selectableMinTime"
						:maxTime="selectableMaxTime"
					/>
					<div class="display-flex">
						<p>終了：</p>
						<p>{{ computedEndTime }}</p>
					</div>
				</div>
				<!-- どのレッスンチケットを使うか -->
				<div>
					<p class="reservation-subtitle">
						使用するレッスンチケット
						<span class="required">※必須</span>
					</p>
					<v-select
						:items="ticketOptions"
						v-model="selectedTicket"
						label="使用するチケットを選択してください"
						solo
					></v-select>
					<p class="current-ticket-count">現在の残り枚数</p>
					<p>
						フィードバックありチケット: {{ feedbackTicketsCount }} 枚<br />
						<span class="date-expiration" v-if="feedbackTicketsCount"
							>(有効期限: {{ nearestFeedbackTicketExpiration }})</span
						>
					</p>
					<p>
						フィードバックなしチケット: {{ noFeedbackTicketsCount }} 枚<br />
						<span class="date-expiration" v-if="noFeedbackTicketsCount"
							>(有効期限: {{ nearestNoFeedbackTicketExpiration }})</span
						>
					</p>
				</div>
				<div>
					<div class="display-flex">
						<p class="reservation-subtitle">
							レッスンの要望
							<span class="required">※必須</span>
						</p>
					</div>
					<div class="display-flex">
						<v-select :items="hopeForLessonItems" v-model="hopeForLesson" label="選択してください" solo></v-select>
					</div>
				</div>
				<div>
					<div class="display-flex">
						<p class="reservation-subtitle">先生へのメッセージ</p>
					</div>
					<div class="display-flex">
						<v-textarea v-model="messageForTeacher" :placeholder="messageForTeacherLabel" solo counter></v-textarea>
					</div>
				</div>
				<div>
					<p class="attention">
						※レッスン開始時刻48時間前「以降」の日時変更はキャンセル扱いとなり、チケットのお返しはしません。
					</p>
				</div>
			</template>
			<template #underButton>
				<div class="underButtonArea">
					予約を完了できない、やり方が分からない場合は<br />
					<ul>
						<li>
							<a href="https://form.run/@LessonReservation" target="_blank" rel="noopener" style="text-decoration: none"
								>レッスン仮予約を依頼する
							</a>
						</li>
					</ul>
				</div>
			</template>
		</VmDialogCard>
		<VmDialogCard cancelButtonTitle="閉じる" @close="cancel" :height="computedCardHeight" v-else>
			<template #cardContent>
				<div class="error-text">
					<div>
						<div class="display-flex">
							<p class="reservation-subtitle">有効なチケットを所持していません。</p>
						</div>
						<p>下記からチケットを購入できます。</p>
						<VmButton text="チケット購入はこちら" :to="{ path: '/buyTicketTop' }" type="primary" class="ticket-button">
							<template #prefix>
								<v-icon color="white" width="80%">mdi-ticket-confirmation</v-icon>
							</template>
						</VmButton>
					</div>
				</div>
			</template>
		</VmDialogCard>
	</div>
</template>

<script>
import userInfoTranslate from '@/data/translation/userInfoTranslate'
import TimeZoneIcon from '../Atoms/TimeZoneIcon'
import DateAndTimeForm from '../Molecules/DateAndTimeForm'
import VmDialogCard from '../Molecules/VmDialogCard'
import SharedStudentInfo from '../Molecules/SharedStudentInfo'
import { mapGetters, mapActions } from 'vuex'
import VmButton from '../Atoms/VmButton'

export default {
	name: 'ConversationReservationFormCard',
	inject: ['reloadHeader'],
	components: {
		TimeZoneIcon,
		DateAndTimeForm,
		VmDialogCard,
		SharedStudentInfo,
		VmButton
	},
	data: () => ({
		textFontWeight: 'bold',
		lessonStartDate: '2020-12-01',
		lessonStartTime: '00:00',
		hopeForLesson: '',
		messageForTeacher: '',
		hopeForLessonItems: [
			'フリートーク（例: Was hast du am Wochenende gemacht?）',
			'テーマに沿った会話（例: Soll man Englisch können?）',
			"日本文化を紹介（例: Was ist 'Hinamatsuri'?）",
			'スピーキング試験対策'
		],
		messageForTeacherLabel: '（例）話すのが苦手なのでゆっくりお願いします。テーマ◯◯について話したいです。',
		selectedTicket: null
	}),
	props: [
		'teacherName',
		'courseName',
		'startTime',
		'endTime',
		'selectedOpen',
		'studentId',
		'teacherId',
		'lessonCourseId'
	],
	created() {
		// 会話チケット枚数を更新する
		this.fetchConversationTickets({
			accessToken: this.userInfo.accessToken,
			studentId: this.userInfo.studentId
		})
	},
	computed: {
		...mapGetters({
			userInfo: 'user/getUserInfo',
			loading: 'reserve/loading',
			student: 'student/student',
			conversationTicketsLoading: 'conversationTickets/loading',
			availableConversationTickets: 'conversationTickets/availableConversationTickets'
		}),
		hasTickets() {
			return this.availableConversationTickets && this.availableConversationTickets.length > 0
		},
		feedbackTickets() {
			if (!this.availableConversationTickets) return []
			return this.availableConversationTickets.filter((ticket) => ticket.isFeedback)
		},
		noFeedbackTickets() {
			if (!this.availableConversationTickets) return []
			return this.availableConversationTickets.filter((ticket) => !ticket.isFeedback)
		},
		nearestFeedbackTicketExpiration() {
			if (this.feedbackTickets.length === 0) return null
			const nearest = this.feedbackTickets.reduce((nearest, ticket) => {
				return this.$moment(ticket.expirationDate).isBefore(nearest.expirationDate) ? ticket : nearest
			}).expirationDate
			return this.$moment(nearest).format('YYYY年M月D日HH:mm')
		},
		nearestNoFeedbackTicketExpiration() {
			if (this.noFeedbackTickets.length === 0) return null
			const nearest = this.noFeedbackTickets.reduce((nearest, ticket) => {
				return this.$moment(ticket.expirationDate).isBefore(nearest.expirationDate) ? ticket : nearest
			}).expirationDate
			return this.$moment(nearest).format('YYYY年M月D日HH:mm')
		},
		feedbackTicketsCount() {
			return this.feedbackTickets.reduce((sum, ticket) => sum + ticket.currentTicketCount, 0)
		},
		noFeedbackTicketsCount() {
			return this.noFeedbackTickets.reduce((sum, ticket) => sum + ticket.currentTicketCount, 0)
		},
		ticketOptions() {
			if (!this.availableConversationTickets) return []
			const options = []
			if (this.availableConversationTickets.some((ticket) => ticket.isFeedback)) {
				options.push({
					text: 'フィードバックあり',
					value: 'feedback'
				})
			}
			if (this.availableConversationTickets.some((ticket) => !ticket.isFeedback)) {
				options.push({
					text: 'フィードバックなし',
					value: 'noFeedback'
				})
			}
			return options
		},
		// レッスン予約できない時はカードの高さを下げる
		computedCardHeight() {
			if (this.canReserve) {
				return 500
			} else {
				return 300
			}
		},
		selectableMinDate() {
			return this.$moment(this.startTime).format('YYYY-MM-DD')
		},
		selectableMaxDate() {
			let endTime = this.$moment(this.endTime)

			// endTimeが00:00の場合、1日減らす
			if (endTime.format('HH:mm') === '00:00') {
				endTime = endTime.subtract(1, 'days')
			}

			return endTime.format('YYYY-MM-DD')
		},
		selectableMinTime() {
			if (this.lessonStartDate === this.$moment(this.startTime).format('YYYY-MM-DD')) {
				return this.$moment(this.startTime).format('HH:mm')
			} else {
				return '00:00'
			}
		},
		selectableMaxTime() {
			if (this.lessonStartDate === this.$moment(this.endTime).format('YYYY-MM-DD')) {
				return this.$moment(this.endTime).subtract(20, 'm').format('HH:mm')
			} else {
				return '24:00'
			}
		},
		computedStartTime() {
			const start = this.lessonStartDate + ' ' + this.lessonStartTime
			return this.$moment(start).format('YYYY-MM-DD HH:mm')
		},

		// 「レッスン開始時刻」にレッスン時間を足し、「レッスン終了時間」を算出する。
		computedEndTime() {
			return this.$moment(this.computedStartTime).add(20, 'm').format('YYYY-MM-DD HH:mm')
		},
		computedHopeForLesson: {
			get() {
				return userInfoTranslate[this.hopeForLesson]
			},
			set(value) {
				this.hopeForLesson = userInfoTranslate[value]
			}
		},
		timeRangeValidation() {
			// 講師予定の開始時刻 < 開始時刻となっているか
			if (!this.$moment(this.computedStartTime).isSameOrAfter(this.$moment(this.startTime))) {
				return false
			}
			// 終了時刻 < 講師予定の終了時刻となっているか
			if (!this.$moment(this.endTime).isSameOrAfter(this.$moment(this.computedEndTime))) {
				return false
			}

			return true
		},
		buttonValidation() {
			// バリデーション - 指定した時間が講師予定の範囲に収まっているかどうか判定
			if (!this.timeRangeValidation) {
				return false
			}

			// レッスン要望は必須
			if (!this.hopeForLesson) {
				return false
			}

			// フィードバックは必須
			if (!this.selectedTicket) {
				return false
			}

			return true
		}
	},
	mounted() {
		this.initClassStartDate()
	},
	methods: {
		...mapActions({
			conversationReserve: 'reserve/conversationReserve',
			fetchConversationTickets: 'conversationTickets/fetchConversationTickets'
		}),
		initClassStartDate() {
			this.lessonStartDate = this.$moment(this.startTime).format('YYYY-MM-DD')
			this.lessonStartTime = this.$moment(this.startTime).format('HH:mm')
		},
		// 会話コースの予約
		async reserve() {
			if (
				confirm(
					`【予約確認】\n 予約を完了しますか？ \n レッスン開始時刻： ${this.computedStartTime} \n レッスン終了時刻： ${this.computedEndTime} \n ※「はい」を押すとチケットが1枚消費され予約が完了します。`
				)
			) {
				const reserveData = {
					studentId: this.studentId,
					teacherId: this.teacherId,
					lessonCourseId: this.lessonCourseId,
					startTime: this.$convertToPost(this.computedStartTime),
					endTime: this.$convertToPost(this.computedEndTime),
					hopeForLesson: this.computedHopeForLesson,
					messageForTeacher: this.messageForTeacher,
					status: 0,
					isFeedback: this.selectedTicket === 'feedback'
				}

				const isSuccess = await this.conversationReserve({
					accessToken: this.userInfo.accessToken,
					reserveData: reserveData
				})

				if (isSuccess) {
					// 会話チケット枚数を更新する
					this.fetchConversationTickets({
						accessToken: this.userInfo.accessToken,
						studentId: this.userInfo.studentId
					})
					this.$router.push({
						name: 'CompleteConversationReservation',
						params: {
							teacherId: this.teacherId
						}
					})
					this.reloadHeader()
				}
			}
		},
		cancel() {
			this.$emit('cancelAction')
		}
	},
	head: {}
}
</script>

<style lang="scss" scoped>
.lesson-info-area {
	padding: 12px;
}

.reservation-subtitle {
	font-size: 1.4em;
	font-weight: bold;
	margin: 20px 0 15px 0;
}
.display-flex {
	display: flex;
	align-items: center;
}
.end-time {
	padding: 12px 12px 12px 0;
	font-size: 1.2em;
}
.button {
	margin-bottom: 20px;
}
.required {
	font-weight: normal;
	font-size: 0.8em;
}
.attention {
	width: 100%;
	margin: 0;
}
.error-text {
	margin-top: 30px;
}
.underButtonArea {
	padding: 0 16px;
	margin: 30px 0 20px 0;
	font-size: 0.8em;
}
.ticket-button {
	margin-bottom: 30px;
}
.date-expiration {
	font-size: 0.9em;
}
.current-ticket-count {
	margin-bottom: 4px;
	font-weight: bold;
	font-size: 1.2em;
}
</style>
